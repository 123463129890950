import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default function linkTo() {
  const links = document.querySelectorAll('.js-link-to');
  const burger = document.querySelector('.js-burger');
  const mobilePane = document.querySelector('.js-mobile-pane');

  if (!links || links.length === 0) return;

  const scrollTo = (e) => {
    e.preventDefault();
    
    const el = e.target.closest('.js-link-to');
    const id = el.getAttribute('href');
    const section = document.querySelector(id);

    if(burger && mobilePane) {
      burger.classList.remove('_active')
      mobilePane.classList.remove('_active')
      document.body.classList.remove('_lock')
    }
    
    if (section) {
      gsap.to(window, {duration: 1, scrollTo: section.offsetTop});
    }
  }

  links.forEach(link => {
    link.addEventListener('click', scrollTo);
  });
}
 