import Inputmask from "inputmask";
import JustValidate from 'just-validate';
import axios from 'axios';
import MicroModal from "micromodal";
import {getScrollbarWidth} from "../utils/utils";

export default function forms() {

  const phones = document.querySelectorAll(".js-phone-mask");

	if (phones.length > 0) {
		const im = new Inputmask("+7 (999) 999-99-99");

		phones.forEach(phoneInput => {
			im.mask(phoneInput);
		})
	}

  const forms = document.querySelectorAll('.js-form'); 

  if (forms.length > 0) {
    forms.forEach(form => {
      const validate = new JustValidate(form, {
        errorFieldCssClass: '_error',
        errorLabelCssClass: 'label-error'
      });

      if(form.name !== 'friday') {
        validate.addField('[name="fio"]', [
          {
            rule: 'required',
            errorMessage: 'Обязательное поле',
          },
        ]);

        validate.addField('[name="politic"]', [
          {
            rule: 'required',
            errorMessage: 'Обязательное поле',
          },
        ]);
      }

      validate.addField('[name="phone"]', [
        {
          rule: 'required',
          errorMessage: 'Обязательное поле',
        },
      ])

      validate.onSuccess(() => {
        const fd = new FormData(form);
        const goalInput = form.querySelector('input[name="goal"]');
        
        axios.post('/sendMail.php', fd)
        .then(function (response) {
          if (response.data.success) {
            const modal = document.querySelector(".modal.is-open");
            if(goalInput) ym(89550467, "reachGoal", goalInput.value);

            if (modal) MicroModal.close("sendModal");

            MicroModal.show("successModal", {
              onShow: () => {
                const scWidth = getScrollbarWidth();
                document.body.classList.add('_lock')
                document.body.style.paddingRight = `${scWidth}px`;
              },
              onClose: () => {
                document.body.classList.remove('_lock')
                document.body.style.paddingRight = ``;
              }
            });

            form.reset()
          }

        })
        .catch(function (error) {
          console.log(error);
        });
      });
    })
  }
}
 