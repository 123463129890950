export default (value, digits) => {
  const d = digits || 2;

  const isNegative = Number(value) < 0;
  let buffer = value.toString();

  if (isNegative) {
    buffer = buffer.slice(1);
  }

  const size = d - buffer.length + 1;
  buffer = new Array(size).join('0').concat(buffer);

  return `${isNegative ? '-' : ''}${buffer}`;
};
