export default function fridayPane() {
	const pane = document.querySelector('.friday-pane');
	const heroBlock = document.querySelector('.hero-section');

	if (pane && heroBlock) {
		let sizes = {
			width: 0,
			height: 0,
			offsetTop: 0,
		};

		const handleResize = () => {
			const bounds = heroBlock.getBoundingClientRect();

			sizes = {
				width: bounds.width,
				height: bounds.height,
				offsetTop: heroBlock.offsetTop
			}
		}

		const handleScroll =  () => {
			const { scrollY } = window;

			pane.classList.toggle('_active', scrollY >= sizes.height + sizes.offsetTop);
		}

		handleResize();

		window.addEventListener('resize', handleResize);
		window.addEventListener('scroll', handleScroll);
	}
}