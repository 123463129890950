import getSiblings from "../utils/getSiblings";
import outsideClickListener from "../utils/outsideClickListener";


export default function ProductMap() {
	const maps = document.querySelectorAll('.js-product-map');

	maps.forEach(map => {
		const points = map.querySelectorAll('.js-point');

		points.forEach(point => {
			const btn = point.querySelector('button');

			const siblings = getSiblings(point);

			btn.addEventListener('click', (e) => {
				e.preventDefault();

				points.forEach((sibling) => sibling.classList.remove('_inActive'));

				point.classList.toggle('_active');

				if (point.classList.contains('_active')) {
					siblings.forEach((sibling) => {
						sibling.classList.add('_inActive');
						sibling.classList.remove('_active');
					});
				}
			});

			outsideClickListener(points, () => {
				points.forEach((sibling) => {
					sibling.classList.remove('_inActive');
					sibling.classList.remove('_active');
				});
			})
		});
	});
}
