import addZero from "../utils/addZero";

export default function counter() {
	const counters = document.querySelectorAll('.time-counter');

	counters.forEach((counter) => {
		const text = counter.querySelector('.time-counter__element');

		const dataStr = counter.dataset.date;
		const targetTime = new Date(dataStr).getTime();

		const interval = setInterval(() => {
			const nowDate = new Date();
			const diff = targetTime - nowDate.getTime();

			const diffDays = diff / (1000 * 60 * 60 * 24);

			const days = Math.floor(diffDays);
			const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((diff % (1000 * 60)) / 1000);

			text.innerHTML = ' <span class="time-counter__item">'+days+'<span>дней</span></span>';
			text.innerHTML += ' <span class="time-counter__item">' + addZero(hours) + ':' + addZero(minutes) + ':' + addZero(seconds) + '</span>';

			if (diff <= 0) {
				clearInterval(interval);

				text.innerHTML = '';
			}
		}, 1000);
	})
}