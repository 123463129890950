export default function Tabs() {

	const tabs = document.querySelectorAll('.tabs');

	tabs.forEach(element => {
		const tabLinks = element.querySelectorAll('.tabs-nav__link');
		const contentItems = element.querySelectorAll('.tabs-content__item');

		tabLinks.forEach(link => {
			const href = link.getAttribute('href');
			const currentContent = element.querySelector(href)

				link.addEventListener('click', (e) => {
					e.preventDefault();

					if (currentContent) {
						tabLinks.forEach(l => l.classList.remove('_active'));
						contentItems.forEach(c => c.classList.remove('_active'));

						link.classList.add('_active');
						currentContent.classList.add('_active');
					}
				})
		})
	});
}
