export default (elements, cb, event = 'click') => {
	window.addEventListener(event, function (e) {
		const targets = [];

		elements.forEach(element => {
			if (element.contains(e.target)) {
				targets.push(true);
			}
		});

		if(targets.length === 0) {
			cb();
		}
	});
}