import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import { BREAKPOINTS } from '../utils/variables';
import { checkViewPort } from '../utils/utils'

export default function mainAnimate() {
  const hero = document.querySelector('.hero-section')
  // const ill = hero.querySelector('.hero-section__illustration')
  // let tween = null;
  // let isDestroy = checkViewPort(BREAKPOINTS.md);

  // if (!hero) return;

  // const init = () => {
  //   tween = gsap.to(ill, {
  //     yPercent: -70,
  //     duration: 1,
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: hero,
  //       start: "top top",
  //       end: "=+100%",
  //       scrub: 1,
  //     }
  //   })
  // }
  //
  // const destroy = () => {
  //   tween.scrollTrigger.kill(true)
  //   tween.kill();
  // }
  //
  // if(!checkViewPort(BREAKPOINTS.md)) {
  //   init();
  // }
  //
  // window.addEventListener('resize', () => {
  //   if(!checkViewPort(BREAKPOINTS.md) && isDestroy){
  //     isDestroy = false;
  //     init();
  //   } else if(checkViewPort(BREAKPOINTS.md) && !isDestroy && tween) {
  //     destroy();
  //     isDestroy = true;
  //   }
  // })

  /* ANIMATE HERO */

  const tl = gsap.timeline();

  const dots = document.querySelectorAll('.js-logo-dot');
  const logoTitle = document.querySelectorAll('.js-logo-text');
  const heroTitle = document.querySelectorAll('.js-hero-title');
  const heroSubTitle = document.querySelectorAll('.js-hero-subtitle');
  const heroFooter = document.querySelectorAll('.js-hero-footer');
  const heroLogos = document.querySelectorAll('.js-hero-logos');
  const heroSide = document.querySelector('.hero-section__side');

  tl.to(dots, {
    x: 0,
    duration: 0.8,
    ease: 'power1.inOut'
  }).to(logoTitle, {
    opacity: 1,
    duration: 0.8,
    ease: 'sine.out'
  })

  if(hero) {
    tl.to(heroSubTitle, {
      opacity: 1,
      y: 0,
      duration: 0.8,
      ease: 'sine.out'
    }, "-=.6").to(heroTitle, {
      opacity: 1,
      y: 0,
      duration: 0.8,
      ease: 'sine.out'
    }, "-=.6")
      .to(heroFooter, {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: 'sine.out'
      }, "-=.6").to(heroSide, {
      opacity: 1,
      duration: 0.8,
      ease: 'sine.out'
    });
  }
}
 